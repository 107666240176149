<template>
  <div class="edu-frame">
    <div style="height: calc(100vh - 50px);overflow: auto">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-if="activity.promoPic1 != null && activity.promoPic1 != ''"><img :src="activity.promoPic1"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic2 != null && activity.promoPic2 != ''"><img :src="activity.promoPic2"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic3 != null && activity.promoPic3 != ''"><img :src="activity.promoPic3"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic4 != null && activity.promoPic4 != ''"><img :src="activity.promoPic4"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic5 != null && activity.promoPic5 != ''"><img :src="activity.promoPic5"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic6 != null && activity.promoPic6 != ''"><img :src="activity.promoPic6"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic7 != null && activity.promoPic7 != ''"><img :src="activity.promoPic7"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic8 != null && activity.promoPic8 != ''"><img :src="activity.promoPic8"></van-swipe-item>
        <van-swipe-item v-if="activity.promoPic9 != null && activity.promoPic9 != ''"><img :src="activity.promoPic9"></van-swipe-item>
      </van-swipe>
      <div class="activity-title">{{activity.activityName}}</div>
      <div class="activity-time">报名时间：
      <div style="margin-top: 3px;">{{activity.beginTime | filterDate}} - {{activity.endTime | filterDate}}</div>
      </div>
      <div class="activity-content">
        <div v-html="activity.intro" class="activity-content-detail-all">
        </div>
<!--        <div @click="changeShowType">{{ showType }}</div>-->
      </div>
    </div>
    <div style="background-color: white;height: 50px;">
      <van-button :loading="submitting" type="primary" block size="large" @click="toPay">报名</van-button>
    </div>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Button } from "vant";
import ActivityApi from "@/api/ActivityApi";
import XEUtils from "xe-utils";
import wx from "weixin-js-sdk";
export default {
  name: 'actinfo',
  components: {
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem,
    VanButton: Button
  },
  filters: {
    filterDate(date) {
      return date != null && date != '' ? XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm') : ''
    }
  },
  data() {
    return {
      showType: '... 展开',
      activity: {},
      submitting: false
    }
  },
  methods: {
    changeShowType() {
      if (this.showType.indexOf('展开') > -1) {
        this.showType = '<< 收起'
      } else if (this.showType.indexOf('收起') > -1) {
        this.showType = '... 展开'
      }
    },
    toPay() {
      this.$router.push({ name: 'actform', query: { activitySettingMasterId: this.activity.activitySettingMasterID } })
    },
    setShareConfig() {
      // alert('测试分享。')
      var stitle = this.activity.activityName

      wx.updateAppMessageShareData({
        title: stitle, // 分享标题
        desc: '省妇儿活动中心教学管理系统', // 分享描述
        link: 'https://edu.gdfezx.com/qxapi/edu/wechat/oauthforact?page=actinfo&activitySettingMasterId=' + this.activity.activitySettingMasterID, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
        success: function () {
          // 设置成功
          // alert('分享给朋友成功。')
        },
        cancel: function() {
          // alert('cancel');
        },
        fail: function(res) {
          // alert('fail'+JSON.stringify(res));
          console.log(res)
        }
      })

      wx.updateTimelineShareData({
        title: stitle, // 分享标题
        link: 'https://edu.gdfezx.com/qxapi/edu/wechat/oauthforact?page=actinfo&activitySettingMasterId=' + this.activity.activitySettingMasterID,// window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
        success: function () {
          // 设置成功
          // alert('分享到朋友圈成功。')
        },
        cancel: function() {
          // alert('cancel');
        },
        fail: function(res) {
          // alert('fail'+JSON.stringify(res));
          console.log(res)
        }
      })
    }
  },
  mounted() {
    var openId = this.$route.query.openid
    // alert(openId)
    if (openId != null && openId != '') {
      sessionStorage.setItem('openId', openId)
    }


    var activitySettingMasterId = this.$route.query.activitySettingMasterId
    // console.log(activitySettingMasterId)
    this.submitting = true
    ActivityApi.getActivityById(activitySettingMasterId).then(response => {
      this.submitting = false
      this.activity = response.res
      this.activity.intro = this.activity.intro.replaceAll('\n', '</br>').replaceAll('<img', '<img style="width: 100%" ').replaceAll('class="ql-indent-2"', 'style="margin-left: 60px"')
      console.log(this.activity)
      this.setShareConfig()
    }).catch(() => {
      this.submitting = false
    })
  }
}
</script>
<style scoped>
.edu-frame {
  background-color: #eeeeee;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.van-swipe-item img {
  width: 100%;
}

.activity-title {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background-color: white;
  font-weight: bold;
  font-size: 16px;
}

.activity-time {
  height: 50px;
  background-color: white;
  margin-top: 15px;
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
}

.activity-content {
  background-color: white;
  margin: 15px 0px;
  display: flex;
  padding: 15px 20px;
  flex-direction: column;

}
.activity-content-detail {
  display: -webkit-box;  /*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
  -webkit-box-orient: vertical; /*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
  -webkit-line-clamp: 10; /*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
  overflow: hidden;
}

.activity-content-detail-all {

}

.activity-content-detail-all img{
  width: 100%;
}

.ql-syntax {
  white-space: pre-wrap; /*css-3*/
  white-space: -moz-pre-wrap; /*Mozilla,since1999*/
  white-space: -o-pre-wrap; /*Opera7*/
  word-wrap: break-word; /*InternetExplorer5.5+*/
}
.ql-indent-2 {
  margin-left: 20px !important;
}
</style>
